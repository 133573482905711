@use "mixins" as mixins;
@use "o-wrapper-base" as oWrapperBase;

.o-wrapper {
  @include oWrapperBase.o-wrapper-base;
  @include mixins.clearfix();
}

@media (max-width: 40rem) {
  .o-wrapper {
    @include oWrapperBase.o-wrapper-base;
  }
}
