/* State-Colors for Icons */

fa-icon.isError {
  color: var(--errorColor);
}
fa-icon.isPrimary {
  color: var(--primaryColor);
}
fa-icon.isWarning {
  color: var(--warningColor);
}
fa-icon.isSuccess {
  color: var(--successColor);
}
