@use 'mixins' as mixins;
$card-v-padding: 1.3rem;
$card-h-padding: 1.5rem;
$card-border-radius: 4px;
$card-title-margin-bottom: calc(0.5rem + 2vh);
$card-title-height: 2.5rem;

mat-card.c-card {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  padding: #{$card-v-padding} #{$card-h-padding};
  border-radius: $card-border-radius;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;

  // Disable Scrollbars
  &--noScroll {
    overflow: hidden;
  }

  // Scroll-Indicator
  background: white;
  background-size: 100% 200%;
  transition: background-size 0.5s ease-in-out;

  &--hasBottomOverflow {
    background-size: 100% 100%;
  }
}

mat-card.c-card .mat-mdc-card-content {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  // align-items: center;
  align-items: stretch;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  font-size: inherit;
}

mat-card.c-card .mat-mdc-card-title.c-card-title {
  position: relative;
  align-self: stretch;
  margin: -#{$card-v-padding} -#{$card-h-padding};
  height: $card-title-height;
  min-height: $card-title-height;
  margin-bottom: $card-title-margin-bottom;
  background: var(--secondaryCardColor);
  border-bottom: 1px rgba(0, 0, 0, 0.05) solid;
  border-top-left-radius: $card-border-radius;
  border-top-right-radius: $card-border-radius;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 200 !important;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.12);

  h2 {
    margin: 0;
    font-size: 1.1rem;
    font-weight: 600;
    @include mixins.truncate();
    padding: 0 0.25rem;
  }

  fa-icon {
    position: absolute;
    right: 0.65rem;
    top: 50%;
    font-size: 0.9rem;
  }
}

mat-card.c-card .mat-mdc-card-actions,
mat-card.c-card .mat-mdc-card-actions:last-child {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -#{$card-h-padding / 2};
  margin-right: -#{$card-h-padding / 2};
  margin-bottom: 0;

  button.mat-flat-button {
    font-weight: 600;
  }
}

/* Stick Elements directly under title (but not sticky) */

mat-card.c-card .mat-mdc-card-title.c-card-title + .c-card__title-stick {
  margin-top: calc(-1 * #{$card-title-margin-bottom});
}
mat-card.c-card .c-card__title-stick:first-child {
  margin-top: -$card-v-padding;
}
mat-card.c-card .mat-mdc-card-title.c-card-title ~ .c-card__title-stick,
mat-card.c-card .c-card__title-stick:first-child {
  margin-left: #{-$card-h-padding};
  margin-right: #{-$card-h-padding};
  margin-bottom: $card-title-margin-bottom;
  z-index: 100;

  & ~ .c-card__title-stick {
    margin-top: calc(-1 * #{$card-title-margin-bottom});
  }

  // Mimic Card-Padding
  &.c-card__title-stick--cardPadding {
    padding-left: #{$card-h-padding};
    padding-right: #{$card-h-padding};
  }

  // Stick only to top (e.g. tab-groups)
  &.c-card__title-stick--onlyTop,
  &.c-tab-group {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;

    // Special Rules for Tab-Headers
    .mat-mdc-tab-header {
      margin: 0 #{-$card-h-padding} #{$card-title-margin-bottom};
    }
  }
}

/* Full-Width Bottom-Stick */

mat-card.c-card .c-card__bottom-stick {
  margin-left: #{-$card-h-padding};
  margin-right: #{-$card-h-padding};
  margin-bottom: #{-$card-v-padding} !important;
  z-index: 0;
}

/* Sticky Title */

@media (min-height: 25rem) {
  mat-card.c-card .mat-mdc-card-title.c-card-title,
  mat-card.c-card .mat-mdc-card-title.c-card-title + app-info-banner {
    position: sticky;
    z-index: 100;
    opacity: 0.925;
    backdrop-filter: blur(1px);
  }

  mat-card.c-card .mat-mdc-card-title.c-card-title {
    top: -#{$card-v-padding};
    margin-bottom: 0;
  }

  mat-card.c-card .mat-mdc-card-title.c-card-title + *:not(.c-card__title-stick) {
    margin-top: $card-title-margin-bottom;
  }
  mat-card.c-card .mat-mdc-card-title.c-card-title + .c-card__title-stick {
    margin-top: 0;
  }

  // .c-card__title-stick Sticky Behavior
  mat-card.c-card .mat-mdc-card-title.c-card-title ~ .c-card__title-stick,
  mat-card.c-card .c-card__title-stick:first-child {
    &.c-card__title-stick--isSticky {
      position: sticky;
      backdrop-filter: blur(1px);
      top: $card-title-height - $card-v-padding;
    }
  }
}

/* Full-Viewport Card */

mat-card.c-card--isFullViewport {
  flex-grow: 1;
  position: relative !important;
  //height: calc(var(--windowHeight) - var(--headerHeight) + 2rem);
  height: calc(var(--windowHeight) - var(--headerHeight) - 2rem);
  @media (max-width: 40rem) {
    height: calc(var(--windowHeight) - var(--headerHeight));
    //height: calc(var(--windowHeight) - var(--headerHeight) - 1.5rem);
    max-height: calc(var(--windowHeight) - var(--headerHeight)) !important;
  }
}

mat-card.c-card--isFullViewport .mat-mdc-card-content {
  align-items: stretch;
}

mat-card.c-card .mat-mdc-card-footer,
mat-card.c-card .mat-mdc-card-actions {
  flex-grow: 1;
  margin-bottom: 0;
  margin-top: 2rem;
  margin-top: calc(1rem + 1.5vh);
}
mat-card.c-card:not(.c-card--isFullViewport) .mat-mdc-card-footer,
mat-card.c-card:not(.c-card--isFullViewport) .mat-mdc-card-actions {
  margin-top: 1.5vh;
  margin-bottom: -0.25rem;
}
mat-card.c-card .mat-mdc-card-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: calc(100% + 1rem);
  margin-left: -0.5rem;
  margin-right: -0.5rem;

  p {
    margin: 0.5rem 0;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.9rem;
    font-style: italic;
    max-width: 60ch;
    line-height: 1.6;
    text-align: center;
  }
  p:first-of-type {
    margin-top: 0;
  }
  p:last-of-type {
    margin-bottom: 0;
  }
  p:last-child {
    margin-bottom: 0.75rem;
  }

  > .mat-mdc-card-actions {
    flex-grow: 0;
    margin-top: 0.75rem;
  }

  // Other Elements inside Footer

  app-info-banner {
    text-align: center;
    margin-bottom: 1.25rem;

    h4 {
      color: rgba(0, 0, 0, 0.5);
    }
    p {
      color: rgba(0, 0, 0, 1);
      max-width: 65ch;
    }
  }

  app-online-doctors {
    font-weight: 600;
  }
}
mat-card.c-card .mat-mdc-card-actions {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-wrap: wrap;

  button, a {
    font-size: 0.95rem;
    line-height: 2.5;
    margin: 0.25rem 0.5rem;
  }
  // button:not(.mat-stroked-button) {
  //   border: 1px transparent solid; // align with stroked-buttons
  // }
  .mat-flat-button {
    font-weight: 600;
  }
}

/* Responsiveness */

@media (max-width: 40rem) {
  $card-h-padding: $card-h-padding / 3;

  mat-card.c-card {
    padding-left: $card-h-padding;
    padding-right: $card-h-padding;
  }
  mat-card.c-card .mat-mdc-card-title.c-card-title {
    margin-left: -#{$card-h-padding};
    margin-right: -#{$card-h-padding};
  }

  // Adjust Sticky-Elements accordingly
  mat-card.c-card .mat-mdc-card-title.c-card-title ~ .c-card__title-stick {
    margin-left: #{-$card-h-padding};
    margin-right: #{-$card-h-padding};

    // Stick only to top (e.g. tab-groups)
    &--onlyTop,
    &.c-tab-group {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 0;

      // Special Rules for Tab-Headers
      .mat-tab-header {
        margin: 0 #{-$card-h-padding} #{$card-title-margin-bottom};
      }
    }
  }
  mat-card.c-card .c-card__bottom-stick {
    margin-left: #{-$card-h-padding};
    margin-right: #{-$card-h-padding};
  }
}

/* Loading Indicator & Overlay */

mat-card.c-card .mat-mdc-card-title.c-card-title mat-progress-bar {
  pointer-events: none;
  position: absolute;
  bottom: -1px;
  left: 0;
  height: 3px;
  right: 0;
  height: 3px;
  transition: transform 0.2s 0.1s ease-in-out;
  // transform: scaleY(1) rotateZ(180deg);
  transform: scaleY(1) translate3d(0, 0, 0);

  &.isHidden {
    // transform: scaleY(0) rotateZ(180deg);
    transform: scaleY(0) translate3d(0, 0, 0);
  }
}

// mat-card.c-card.isLoading:after {
//   content: "";
//   display: block;
//   position: fixed;
//   cursor: progress;
//   z-index: 1000;
//   background: rgba(black, .1);
//   top: 0;
//   left: 0;
//   right: 0;
//   bottom: 0;
// }

/* Docked Footer */
.c-card--docked-footer{
  margin-top: 0.5rem !important;
  .mat-mdc-card-actions {
    margin-top: 0 !important;
  }
}
.docked-footer {
  &:not(:empty) {
    padding: 0 1.5rem;
    position: sticky;
    bottom: -1rem;
    background: var(--primaryCardColor);
    z-index: 100;
    width: calc(100% + 3rem);
    border-top: 1px rgba(0, 0, 0, 0.05) solid;
    box-shadow: 0px -1px 3px 0px rgba(0, 0, 0, 0.12);
    align-self: center;
    margin: 0.5rem -1.5rem -1rem;
  }
  &:empty {
    display: none;
  }
}

/* Infoline below Title (with title-stick) */

.c-card--info-line {
  display: grid;
  padding: 0.3rem 1.5rem 0 1.5rem;

  span {
    justify-self: center;
    text-align: center;
    color: var(--secondaryTextColor);
  }
}
 