.c-button-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 1.25rem -0.25rem 0.75rem;

  > .mat-button,
  > .mat-flat-button,
  > .mat-icon-button,
  > .mat-stroked-button,
  > button,
  > a {
    margin: 0.25rem;
    min-width: auto;
  }

  &--noBottomMargin {
    margin-bottom: -0.25rem;
  }

  &--noTopMargin {
    margin-top: -0.25rem;
  }

  &--centered {
    justify-content: center;
  }
}

// for space-saving icon-buttons
button.mat-mdc-icon-button.mat-mdc-button-base.c-icon-button{
  &--small{
      height: 30px;
      width: 30px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
  }    
}
