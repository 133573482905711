
.c-table.mat-mdc-table {
  width: 100%;

  .mat-mdc-row {
    height: 3.5rem;
  }
}


/* Align last column to right */

.c-table.mat-mdc-table {
  .mat-mdc-row .mat-mdc-cell:last-child {
    text-align: right;
  }
  .mat-mdc-header-row .mat-mdc-header-cell:last-child {
    text-align: right;
  }
  .mat-mdc-header-row .mat-mdc-header-cell:last-child .mat-sort-header-container {
    justify-content: flex-end;

    button {
      margin: 0 0 0 6px;
      order: 1;
    }
  }
}


/* Alternating Rows */

.c-table.mat-mdc-table .mat-mdc-row:nth-child(even) .mat-mdc-cell {
  background: rgba(139, 126, 126, 0.025);
}


/* Border & Rounded Corners */

.c-table.mat-mdc-table {
  $radius: 4px;
  $border: 1px rgba(0,0,0,0.1) solid;

  .mat-mdc-header-cell {
    border: none;
  }
  .mat-mdc-row .mat-mdc-cell {
    border-top: $border;
    border-bottom: none;
  }
  .mat-mdc-row .mat-mdc-cell:first-child {
    border-left: $border;
  }
  .mat-mdc-row .mat-mdc-cell:last-child {
    border-right: $border;
  }
  .mat-mdc-row:last-child .mat-mdc-cell {
    border-bottom: $border;
  }

  .mat-mdc-row:first-child .mat-mdc-cell:first-child {
    border-top-left-radius: $radius;
  }
  .mat-mdc-row:first-child .mat-mdc-cell:last-child {
    border-top-right-radius: $radius;
  }
  .mat-mdc-row:last-child .mat-mdc-cell:first-child {
    border-bottom-left-radius: $radius;
  }
  .mat-mdc-row:last-child .mat-mdc-cell:last-child {
    border-bottom-right-radius: $radius;
  }
}


/* Hovering Rows */

.c-table.c-table--isClickable.mat-mdc-table .mat-mdc-row:hover .mat-mdc-cell,
.c-table.c-table--isClickable.mat-mdc-table .mat-mdc-row:focus .mat-mdc-cell {
  background: rgba(black, 0.04);
  cursor: pointer;
}


/* Icon-Sizing */

.c-table .mat-mdc-cell fa-icon {
  font-size: 1.25em;
}


/* Paginator */

.c-paginator .mat-mdc-paginator-container {
  padding: 8px 16px 0;
}
