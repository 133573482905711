$instructions-v-spacing: 0.75rem;

// .c-instructions {
//   margin: #{$instructions-v-spacing} 0;
// }

.c-instructions > * {
  display: flex;
  align-items: baseline;

  // &:not(:last-of-type) {
  margin-bottom: $instructions-v-spacing;
  // }

  fa-icon, mat-icon {
    width: 1.5rem;
    margin-right: 0.5rem;
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    transform: translateY(0.1em);
  }

  a {
    text-decoration: underline;
  }

  &.isColored fa-icon {
    color: var(--secondaryColor);
  }
}

.c-instructions.c-instructions--alternativeStyle > * {
  margin-bottom: 1.1rem;

  fa-icon {
    font-size: 1.25rem;
    width: 2rem;
    margin-right: 0.6rem;
    transform: translateY(0.2em);
    color: var(--secondaryColor);
  }
}
