.mat-mdc-menu-panel .mat-mdc-menu-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 0.9rem;

  // Icon-Placement (same as header-nav>buttons)
  fa-icon {
    margin-left: 2px; // compensate button border above
    margin-right: 0;
  }
  fa-icon + * {
    margin-left: 0.8rem;
  }

  // Active Indicator-Placement
  app-active-indicator + * {
    margin-left: 0.5rem;
  }

  // Badge-Placement
  &.hasBadge span:not(.c-menu-item-badge) {
    margin-right: 0.6rem;
  }

  &.isActive {
    font-weight: 600;
  }
}

/* Badge */

.c-menu-item-badge {
  display: inline-block;
  margin-left: auto;
  line-height: 100%;
  color: white;
  font-weight: 600;
  font-size: 0.7rem;
  padding: 0.3rem 0.45rem;
  background: rgb(255, 179, 0);
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  text-align: center;
}

/* Volume Slider within Mat-Menu */

.mat-mdc-menu-panel.mat-menu--slider {
  min-width: 0;
  background: rgba(black, 0.8);
  border: 2px rgba(white, 0.1) solid;
  backdrop-filter: blur(2px);
  border-radius: 5px;
  width: 2.5rem;
  margin: 0.25rem 0;
  text-align: center;
  overflow: hidden;

  .mat-menu--slider__volume {
    font-size: 0.8rem;
    font-family: monospace;
    color: white;
    margin-top: 0.1rem;
    margin-bottom: 0.5rem;
  }

  mat-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;
  }
  mat-slider .mat-slider-wrapper {
    left: unset;
  }
  mat-slider .mat-slider-track-wrapper {
    background-color: rgba(white, 0.33) !important;
  }
  mat-slider .mat-slider-thumb {
    background-color: var(--primaryColor) !important;
  }
}

// time pcker menu for timeslot dialog
.mat-mdc-menu-panel.time-picker-menu {
  display: flex;
  justify-content: center;
  align-items: center;
}
