
@use "mixins" as mixins;
:root {
  --image-size: 9rem;
}

.c-person {
  display: flex;
  align-items: center;
  padding: 1.5rem 0;

  &--withBackground {
    padding: 1.5rem 1rem;
    justify-content: center;
    background: rgba(0,0,0,.04);
    border-radius: 4px;
    border: 1px rgba(0,0,0,0.05) solid;
  }

  &--hasVerticalMargin {
    margin: 1rem 0 .5rem;
  }
}

.c-person__image {
  width: var(--image-size);
  height: var(--image-size);
  margin-right: 2rem;
  flex-shrink: 0;

  img {
    width: 100%;
    height: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 50%;
    box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
  }
}

.c-person__body {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-person__name,
h4.c-person__name {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-bottom: 0.25rem;
  letter-spacing: .5px;
  font-weight: 600;
  padding-top: 0;
  margin: 0;
  margin-bottom: .25rem;
}

.c-person__text,
p.c-person__text {
  font-size: .9rem;
  line-height: 1.4;
  font-weight: normal;
  color: rgba(black, .6);
  margin: 0;
}


/* Small Version */

.c-person.c-person--isSmall {
  --image-size: 4rem;
  padding: .75rem 1rem;

  .c-person__body {
    overflow: hidden;
  }

  .c-person__image {
    margin-right: 1rem;

    @media (max-width: 20rem) {
      display: none;
    }
  }

  &:not(.c-person--notTruncate) {
    .c-person--isSmall
    .c-person__name,
    h4.c-person__name,
    .c-person__text,
    p.c-person__text {
      @include mixins.truncate();
    }
  }
}


/* Responsiveness */

@media(max-width: 30rem) {

  .c-person:not(.c-person--isSmall) {
    flex-direction: column;

    .c-person__image {
      margin-right: 0;
      margin-bottom: 1.25rem;
    }
    .c-person__body {
      text-align: center;
    }
  }

}
