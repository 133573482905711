/* Global Helpers */

@use "helpers/grow-vertically";
@use "helpers/visually-hidden";
@use "helpers/wrapper";

/* NOTE: Body-Styles are inlined in 'index.html' */

/* Scroll-Behavior */

html {
  scroll-behavior: smooth;
}

/* Box-Sizing */

html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

// Scrollbar styling
* {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 2px;
  }

  &::-webkit-scrollbar-track {
    background: var(--primaryBackColor);
    border-radius: 3px;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: var(--primaryColor);
    scrollbar-track-color: var(--primaryBackColor);
  }
}

/* Text-Selection Color */

$text-selection-color: rgba(255, 88, 33, 0.25);
::selection {
  background: $text-selection-color;
}
::-moz-selection {
  background: $text-selection-color;
}

// Scale
.c-scale-x2 {
  transform: scale(2);
}
.c-scale-x3 {
  transform: scale(3);
}

/* Minor Material Refinements */

// Mat-Chips
.mat-chip {
  line-height: 1;
}

// Mat-Tooltip
.mat-tooltip {
  background: rgba(0, 0, 0, 0.8) !important;
  font-size: 0.9rem !important;
}

/* Fix overlay-pane within safari (double click for mat-menus) */

.cdk-overlay-connected-position-bounding-box {
  // pointer-events: all !important;
  // position: relative !important;
  z-index: 2000;
}

/* Fix mat-slider thumb cut-off */

.mat-slider-thumb-container {
  z-index: 100 !important;
}

// Flex
.flex-spacer {
  flex-grow: 1;
}

// Fix Icon text flash
// body mat-icon {
//   opacity: 0;
//   animation-name: showIcon;
//   animation-duration: 0.2s;
//   animation-delay: 0.8s;
//   animation-fill-mode: forwards;
// }
// @keyframes showIcon {
//   from {
//     opacity: 0;
//   }
//   to {
//     opacity: 1;
//   }
// }
// .mat-menu-content,
// .c-bottom-action,
// .c-videochat-camera-action {
//   mat-icon {
//     animation-name: none;
//     opacity: 1;
//   }
// }
body mat-icon {
  max-width: 24px;
  overflow: hidden;
}
