@use '@angular/material' as mat;

// @include matM2.all-component-typographies();
@include mat.core();


// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$primary-palette: mat.m2-define-palette(mat.$m2-blue-palette);
$accent-palette: mat.m2-define-palette(mat.$m2-orange-palette);
$warn-palette: mat.m2-define-palette(mat.$m2-red-palette);
$default-hue:300;
$theme: mat.m2-define-light-theme(
  (
    color:
    (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette
    ),
    typography: mat.m2-define-typography-config(),
    density: 0
  )
  );
:root {
  @include mat.all-component-themes($theme);
}
/**
 * Color Documentation: https://material.io/design/color/#tools-for-picking-colors
 **/

// define (or rather "import" pre-defined and rename) the palettes to be used in our app
$error: mat.m2-define-palette(mat.$m2-red-palette);
// $warning: mat.define-palette(mat.$m2-amber-palette);
$success: mat.m2-define-palette(mat.$m2-green-palette); // this used to be light-green-palette. change to lighter hues accordingly?
$breakPointEm: 43em; // https://dev.to/sobhandash/media-queries-and-breakpoints-2022-4gkm
$minButtonHeight: 40px; // https: //zollsoft.atlassian.net/browse/ADI-1154

/**
  * since angular 18, when selecting a button a checkmark symbol appears at the top left corner of the button.
  * This can be seen for instance in the otk patient front-end when selecting bestandpatient, insurance type, bs, appointmenttype, slot
  * and in the otk mfa frontend for different configuration options
  **/
::ng-deep mat-button-toggle button.mat-button-toggle-button span.mat-button-toggle-label-content mat-pseudo-checkbox {
  display: none; // hide a checkmark appearing at the top left of the selected bs
}