// Overwrite with dynamic theming
// Button text
.mat-button,
.mat-stroked-button,
.mdc-button__label,
.mdc-button {
  text-transform: uppercase;
  //font-size: 0.95rem;
  font-size: 14px;
  font-weight: bolder;
  letter-spacing: normal;
}
// Button text
.mat-button,
.mat-stroked-button,
.mdc-button__label,
.mdc-button {
  &:hover {
    filter: brightness(0.95);
    transition: 0.3s;
  }
}

.mat-mdc-icon-button {
  background: rgba($color: #000000, $alpha: 0);
  &:hover {
    background: rgba($color: #000000, $alpha: 0.05);
    transition: 0.3s;
  }
  mat-icon, fa-icon{
    display: flex;
  }
}
// Header button
.main-header {
  .mat-mdc-button {
    color: var(--buttonTextOnColor) !important;
    background-color: hsla(0, 100%, 100%, 0.08) !important;
    &:hover,
    &:focus,
    &:active {
      transition: background-color 0.3s ease-out;
      background-color: hsla(0, 100%, 100%, 0.32) !important;
    }
  }
}
// Normal Button
.mdc-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  color: var(--buttonTextColor) !important;
  background-color: var(--primaryBackColor) !important;
}

// Primary Button
.mdc-button.mat-primary,
.mat-raised-button.mat-primary,
.mat-fab.mat-primary,
.mat-mini-fab.mat-primary {
  background-color: var(--buttonColor) !important;
  color: var(--buttonTextOnColor) !important;
}

// Success Button
.mdc-button.mat-success,
.mat-raised-button.mat-success,
.mat-fab.mat-success,
.mat-mini-fab.mat-success {
  background-color: var(--successColor) !important;
  color: var(--buttonTextOnColor) !important;
}
.mdc-button.mat-warn,
.mat-raised-button.mat-warn,
.mat-fab.mat-warn,
.mat-mini-fab.mat-warn {
  background-color: var(--warningColor) !important;
  color: var(--buttonTextOnColor) !important;
}
.mdc-button.mat-danger,
.mat-raised-button.mat-danger,
.mat-fab.mat-danger,
.mat-mini-fab.mat-danger {
  background-color: var(--errorColor) !important;
  color: var(--buttonTextOnColor) !important;
}
.mat-button.mat-button-disabled,
.mat-icon-button.mat-button-disabled,
.mat-stroked-button.mat-button-disabled,
.mdc-button.mat-mdc-unelevated-button:disabled {
  opacity: 0.3;
  cursor: not-allowed !important;
}

//Test Success Button with text color
.mdc-button.mat-test-success {
  background-color: var(--successColor) !important;
  color: var(--primaryTextOnColor) !important;
}

//Selected select-option color
body .mat-primary .mat-option.mat-selected:not(.mat-option-disabled) {
  color: var(--primaryColor);
  filter: brightness(0.9);
}

// Progress Bar
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__bar-inner{
  display: none;
}
body .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer{
  background-color: var(--primaryColor) !important;
  opacity: 0.5 !important;
}
body .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__primary-bar{
  background-color: var(--primaryColor) !important;
}
body .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__primary-bar {
  background-color: var(--mdc-linear-progress-active-indicator-color);
}

// Card Title
mat-card.c-card .mat-mdc-card-title.c-card-title {
  //background: var(--primaryBackColor) !important;
  background: var(--primaryCardColor) !important;
  border-color: var(--secondaryLineColor) !important;

  h2 {
    color: var(--secondaryTextColor) !important;
  }
}

//Remove space between icon and text on buttons

button,
a {
  &.mat-button,
  &.mat-stroked-button,
  &.mdc-button {
    mat-icon {
      margin: 0 !important;
    }
  }
}

// Fix Tap inkbar misalignment
// .mat-tab-label-active {
//   .mat-tab-label-content {
//     font-weight: bold;

//     &:after {
//       content: "";
//       display: block;
//       position: absolute;
//       width: 100%;
//       height: 2px;
//       background: var(--primaryTextOnColor);
//       right: 0;
//       bottom: 0;
//     }
//   }
// }

// mat-ink-bar {
//   display: none !important;
// }

// Input fields
body .mat-form-field.mat-focused {
  .mat-form-field-label {
    color: var(--primaryColor) !important;
  }
  .mat-form-field-ripple {
    background-color: var(--primaryColor) !important;
  }
}
body .mat-form-field-appearance-outline.mat-focused {
  .mat-form-field-outline-thick {
    color: var(--primaryColor);
  }
}
body .mat-form-field-appearance-outline.mat-form-field-invalid.mat-form-field-invalid .mat-form-field-outline-thick {
  color: var(--errorColor);
}
body .mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  background: rgba($color: #000000, $alpha: 0.01);
}

// Tabbar
.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: var(--primaryColor)!important;
}
.main-header {
  .c-tab-group
    .mat-tab-header
    .mat-tab-header-pagination:not(.mat-tab-header-pagination-disabled)
    .mat-tab-header-pagination-chevron {
    border-color: var(--primaryTextOnColor);
  }
  .c-tab-group .mat-tab-header .mat-tab-header-pagination-disabled .mat-tab-header-pagination-chevron {
    border-color: var(--primaryTextOnColor);
    opacity: 0.5;
  }
  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done,
  .mat-step-header .mat-step-icon-state-edit {
    background-color: var(--primaryTextOnColor) !important;
    color: var(--primaryColor) !important;
  }
  .mat-step-label {
    color: var(--primaryTextOnColor) !important;
    &:not(.mat-step-label-selected) {
      opacity: 0.8;
    }
  }
}
.c-enable-vscroll {
  @media screen and (max-width: 70rem) {
    width: calc(100vw - 32px);
    overflow-x: auto;
    overflow-y: hidden;
  }
}
// Switch slider
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: var(--primaryColor) !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: var(--primaryLineColor) !important;
}



// Light Header
body .light-header {
  .main-header {
    background-color: white!important;
    color: var(--primaryColor) !important;
    box-shadow: inset 0 -2px 3px -3px hsla(0, 0%, 0%, 0.16);
    //box-shadow: inset 0px -1px 3px 0px rgba(0, 0, 0, 0.12);
    border-bottom: 1px var(--secondaryLineColor) solid;
    .right-nav-slot div {
      border-color: var(--primaryColor) !important;
    }

    .mat-mdc-button {
      color: var(--buttonTextColor) !important;
      background-color: var(--primaryBackColor) !important;
    
      &:hover,
      &:focus,
      &:active {
        transition: none;
        background-color: hsla(0, 100%, 100%, 0.32) !important;
      }
    }

    .tab-wrapper {
      .c-tab-group .mdc-tab__text-label {
          color: var(--primaryColor) !important;
      }
      .mat-tab-group.mat-primary .mat-ink-bar,
      .mat-tab-nav-bar.mat-primary .mat-ink-bar {
        background-color: var(--primaryColor);
      } 
      .c-stepper {
        .mat-step-label {
          &:not(.mat-step-label-selected) {
            color: var(--primaryColor) !important;
            opacity: 0.6;
          }

          &.mat-step-label-selected {
            color: var(--primaryColor) !important;
          }
        }

        .mat-stepper-horizontal-line {
          border-top-color: var(--primaryColor);
        }
      }

      .mat-step-header .mat-step-icon {
        background-color: var(--primaryColor)!important;
        color: white!important;
      }

      .mat-step-header .mat-step-icon-selected,
      .mat-step-header .mat-step-icon-state-done,
      .mat-step-header .mat-step-icon-state-edit {
        background-color: var(--primaryColor)!important;
        color: white!important;
      }
    }
  }


}