@use 'mixins' as mixins;
@use 'theme-definitions' as themeDefinitions;
@use '@angular/material' as mat;
$dialog-v-spacing: 0.75rem;
$dialog-h-spacing: 1.25rem;
$dialog-actions-v-spacing: 0.25rem;
$dialog-actions-h-spacing: 0.5rem;

$actions-bar-v-spacing: 1rem;
$actions-bar-h-spacing: 1rem;
$actions-bar-height: calc(3.5rem + #{$actions-bar-v-spacing} * 2);

.c-dialog,
.c-fullscreen-dialog {
  // the line below doesn't work since angular 18
  // i could replace it by @using @angular/material/prebuilt-themes and using the typography
  // of one of those but lets try without, maybe its already being included elsewhere first
  // @extend .mat-typography;

  .mat-mdc-dialog-container {
    background: transparent;
    padding: 0;
    overflow: visible;
    position: relative;
    box-shadow: none;
  }

  .c-dialog__inner {
    background: white;
    box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14),
      0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  }
}

/* Fullscreen Dialog */

.c-fullscreen-dialog {
  width: 100vw;
  width: calc(100vw - 1rem);
  lib-dialog {
    display: grid;
    grid-template-rows: $actions-bar-height calc(100vh - #{$actions-bar-height});
  }
}

/* Backdrop Variants */

.c-dialog-dark-backdrop {
  background: var(--primaryColor);
  filter: brightness(0.1);
}
.c-dialog-transparent-backdrop {
  background: rgba(0, 0, 0, 0.86);
}
.c-dialog-portal-backdrop {
  background-color: rgba(0,0,0,0.6);
}
// .c-dialog-grey-backdrop {
//   background-image: linear-gradient(-180deg, #363636 0%, #141414 100%);
// }
// .c-dialog-blue-backdrop {
//   @include primary-gradient();
// }

/* Inner-Wrapper */

.c-dialog .c-dialog__inner,
.c-fullscreen-dialog .c-dialog__inner {
  position: relative;
  padding: #{$dialog-v-spacing} #{$dialog-h-spacing};
  max-height: calc(var(--vh, 1vh) * 100 - 1rem);
  flex-grow: 1;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 4px;

  &--hasActionsBar {
    max-height: calc(var(--vh, 1vh) * 100 - 1rem - #{$actions-bar-height});
    margin-bottom: -3rem;
  }
}
.c-fullscreen-dialog {
  .c-dialog__inner:not(.c-dialog__inner--hasActionsBar) {
    grid-row: 1 / span 2;
    margin-top: 1rem;
  }
}

/* Floating Actions */

.c-dialog__actions-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;

  > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    padding: 1rem;
    gap: 0.5rem;
  }
}
.c-dialog__actions-bar a,
.c-dialog__actions-bar button {
  min-width: 0;
  font-weight: 600;
  background: hsla(0, 0%, 100%, 0.1) !important;
  color: white !important;

  /*& + a,
  & + button {
    margin-left: 0.5rem;
  }*/

  &:hover,
  &:focus {
    background: hsla(0, 0%, 100%, 0.3);
  }
  &.mat-error {
    background: var(--errorColor);
    &:hover,
    &:focus {
      background: var(--errorColor);
      filter: brightness(1.1);
    }
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }
}

/* (Sub-)title */

.c-dialog .mat-mdc-dialog-title,
.c-fullscreen-dialog .mat-mdc-dialog-title {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  margin-top: -0.1rem;
  // margin-bottom: 1rem;
  // margin-bottom: calc(.5rem + 1vh);
  margin-bottom: 0;
  flex-shrink: 0;
  padding: 0;

  h1,
  p {
    margin-bottom: 0;
  }

  h1 {
    margin-right: 1.5rem;
    line-height: 2;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: calc(1.25rem + 0.25vw);
    flex-grow: 1;
  }
  h1:only-child {
    margin-right: 0;
  }

  > fa-icon {
    font-size: 1.35rem;
  }
  > app-active-indicator {
    transform: translateY(0.55rem);
  }

  p {
    font-size: 0.875rem;
    font-weight: 600;
    line-height: 1.25;
    opacity: 0.5;
    margin-top: -0.25rem;
    margin-bottom: 0.75rem;
  }
  p > fa-icon:first-child {
    margin-right: 0.2rem;
  }
}

/* Close-Button */

.c-dialog__close-button,
button.c-dialog__close-button,
a.c-dialog__close-button {
  display: block;
  text-decoration: none;
  color: inherit;
  font-weight: inherit;
  background: transparent;
  border: 0;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

/* Loading Indicator & Overlay */

.c-dialog .c-dialog__inner > mat-progress-bar,
.c-fullscreen-dialog .c-dialog__inner > mat-progress-bar {
  pointer-events: none;
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  width: 100%;
  height: 4px;
  transition: transform 0.2s ease-in-out;
  // transform: scaleY(0) rotateZ(180deg);
  transform: scale(1, 0) translate3d(0, 0, 0);

  &.isShown {
    // transform: scaleY(1) rotateZ(180deg);
    transform: scale(1, 1.1) translate3d(0, 0, 0);
  }
}

/* Content */

.c-dialog .mat-mdc-dialog-content,
.c-fullscreen-dialog .mat-mdc-dialog-content {
  margin: #{$dialog-h-spacing - $dialog-v-spacing} #{- $dialog-h-spacing};
  padding: 0 #{$dialog-h-spacing};

  a {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
}
.c-dialog .mat-mdc-dialog-content {
  max-height: 70vh;
  max-height: calc(var(--vh, 1vh) * 70);

  @media (max-width: 40rem) {
    max-height: none;
  }
}
.c-dialog .c-dialog__inner--hasFixedHeight .mat-mdc-dialog-content {
  max-height: none;
}

/* Content Scrollbar Appearance (https://codepen.io/stevenlewis/pen/hubpL) */

.c-dialog .mat-mdc-dialog-content,
.c-fullscreen-dialog .mat-mdc-dialog-content {
  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }
  &::-webkit-scrollbar:vertical {
    width: 9px;
  }
  &::-webkit-scrollbar:horizontal {
    height: 9px;
  }
  &::-webkit-scrollbar-thumb {
    // border-radius: 8px;
    border: 1px solid white; /* should match background, can't be transparent */
    background-color: rgba(0, 0, 0, 0.5);
  }
}

/* Actions */

.c-dialog .mat-mdc-dialog-actions,
.c-fullscreen-dialog .mat-mdc-dialog-actions {
  margin: 0 #{- $dialog-actions-h-spacing};
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;

  > * {
    margin: $dialog-actions-v-spacing $dialog-actions-h-spacing;
  }

  .mat-flat-button {
    font-weight: 600;
  }
}

/* States */

.c-dialog .c-dialog__inner--isError,
.c-fullscreen-dialog .c-dialog__inner--isError {
  .mat-mdc-dialog-title p {
    color: var(--errorColor);
    opacity: 1;
    line-height: 1.5;
  }
}

/* Hide Dialog-Title (but still show close button) */

.c-dialog .c-dialog__inner--hideTitle,
.c-fullscreen-dialog .c-dialog__inner--hideTitle {
  margin-top: -0.5rem;

  .mat-mdc-dialog-title h1 {
    @include mixins.visually-hidden();
  }
  .mat-mdc-dialog-title .c-dialog__close-button {
    margin-left: auto !important;
    z-index: 100 !important;
    position: relative !important;
    transform: translateY(0.9rem);
  }
  .mat-mdc-dialog-content {
    margin-top: -2.9rem;
  }
  .mat-mdc-dialog-content .c-tab-group .mat-tab-header {
    margin-bottom: 2rem;
  }
}

/* Tab-Group Inside */
.c-dialog .c-dialog__inner--hasTabGroup,
.c-fullscreen-dialog .c-dialog__inner--hasTabGroup {
  // padding-bottom: 0;

  &.c-dialog__inner--hideTitle .mat-mdc-dialog-title {
    margin-bottom: 2rem;
  }
  .mat-mdc-dialog-content {
    // overflow: visible;
    margin-bottom: 0;
  }
  .mat-mdc-dialog-content .c-tab-group {
    .mat-tab-header {
      // position: sticky;
      // top: #{- $dialog-spacing};
      // background: rgba(white, .95);
      // z-index: 50;
      margin: #{- $dialog-v-spacing} #{- $dialog-h-spacing} 1.5rem;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }
  }
}

/* No Dialog Actions are shown */

.c-dialog .c-dialog__inner--noDialogActions,
.c-fullscreen-dialog .c-dialog__inner--noDialogActions {
  padding-bottom: 0;

  .mat-mdc-dialog-content {
    margin-bottom: 0;
  }
}

/* Dialog without Padding */

.c-dialog .c-dialog__inner--noPadding,
.c-fullscreen-dialog .c-dialog__inner--noPadding {
  padding: 0;

  .mat-mdc-dialog-content {
    padding: 0;
    margin: 0;
  }
}

/* Transparent dialog with overflow */

.c-dialog__inner--isTransparent {
  background: transparent !important;
  overflow: visible !important;
  box-shadow: none !important;

  > mat-progress-bar {
    border-radius: 1rem;
    top: -5px;
    height: 2px;
  }
}

/* Responsiveness */

@media (max-width: 30rem) {
  $dialog-v-spacing: 0.5rem;
  $dialog-h-spacing: 0.5rem;

  .c-dialog .c-dialog__inner:not(.c-dialog__inner--noPadding),
  .c-fullscreen-dialog .c-dialog__inner:not(.c-dialog__inner--noPadding) {
    padding: #{$dialog-v-spacing} #{$dialog-h-spacing};

    &.c-dialog__inner--hasTabGroup {
      .mat-mdc-dialog-content .c-tab-group {
        .mat-tab-header {
          margin: #{- $dialog-v-spacing} #{- $dialog-h-spacing} 1.5rem;
        }
      }
    }

    .mat-mdc-dialog-content {
      margin: 0.25rem #{- $dialog-h-spacing};
      padding: 0 #{$dialog-h-spacing};
    }
    .mat-mdc-dialog-title {
      padding: 0 0.25rem;
    }
    .mat-mdc-dialog-actions {
      margin: 0 #{- $dialog-actions-h-spacing};
      > * {
        margin: $dialog-actions-v-spacing $dialog-actions-h-spacing;
      }
    }

    .mat-mdc-dialog-content > .c-form {
      margin-left: -$dialog-v-spacing;
      margin-right: -$dialog-v-spacing;
    }
  }
}

.mat-mdc-dialog-actions {
  min-height: auto !important;
  @media (max-width: 30rem) {
    padding-bottom: 1rem;
    button {
      white-space: normal;
      white-space: break-spaces;
      text-align: left;
    }
  }
}

.waitinglist-dialog-class {
  min-width: 100vw;
  @media screen and (min-width: themeDefinitions.$breakPointEm) {
    min-width: 70vw;
  }
}
