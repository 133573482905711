
$radio-v-spacing: .25rem;
$radio-h-spacing: .5rem;


.mat-radio-group.c-radio-group {
  user-select: none;
  // margin-bottom: 1.75rem !important;
  margin-bottom: 1.35rem !important;

  > h4 {
    // Adapt Style of Mat-Labels
    color: rgba(0, 0, 0, 0.54);
    font-size: .75rem;
    // font-size: .6375rem;
    font-weight: normal;
    margin-top: 0;
    margin-bottom: .4375rem;
    // margin-bottom: .5rem;
    // line-height: 1.125;
    line-height: 1.5;
  }

  > p {
    color: rgba(0, 0, 0, 0.54);
    font-weight: normal;
    margin-top: .25rem;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    margin: -#{$radio-v-spacing} -#{$radio-h-spacing};
  }

  > div > .mat-radio-button {
    margin: #{$radio-v-spacing} #{$radio-h-spacing};
  }
}

.mat-radio-group.c-radio-group .mat-radio-container {
  transform: scale(.9);
}


/* Mat-Hint */

.mat-radio-group.c-radio-group .mat-hint {
  font-size: .75rem;
  margin-top: .5rem;
  display: block;
}

/* radio button in primary color */

mat-radio-button.mat-mdc-radio-button.mat-primary.mat-mdc-radio-checked .mdc-form-field {
  .mdc-radio .mdc-radio__background,
  .mdc-radio:not(:disabled):active .mdc-radio__background,
  .mdc-radio:hover .mdc-radio__background { 
    .mdc-radio__outer-circle,
    .mdc-radio__inner-circle{
      border-color: var(--primaryColor);
    }
  }
}

