/** 
* section header with leading line
*/
.field-label {
  color: var(--secondaryTextColor);
  font-weight: 600;
  font-size: 1.125rem;
  margin-bottom: 1rem !important;

  &:before {
    content: "";
    display: inline-block;
    height: 1px;
    width: 1.25rem;
    background: var(--secondaryTextColor);
    opacity: 0.5;
    margin: 0 0.25rem 0.2rem 0;
  }
  &:not(:first-of-type) {
    margin-top: 0.5rem !important;
  }
}
/** 
* section sub header from section header with leading line
*/
.field-sub-label {
  color: var(--secondaryTextColor);
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0.5rem !important;
}

.field-label + .field-sub-label {
  margin-top: 0 !important;
}