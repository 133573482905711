.mat-mdc-snack-bar-handset {
  width: auto !important;
}

mat-snack-bar-container.c-snackbar.mat-mdc-snack-bar-container {
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  //background: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.9);
  margin: 0.75rem;
  padding: 0.5rem 1rem;

  simple-snack-bar.mat-mdc-simple-snack-bar {
    font-size: 0.9rem;
    line-height: 1.6;
    text-align: center;
  }

  @media (max-width: 40rem) {
    margin: 0.5rem;
  }
}

.warning-snackbar {
  background: var(--warningColor);
  color: rgba(0, 0, 0, 0.8);
}

mat-snack-bar-container.council-tomedoData-snackbar.mat-mdc-snack-bar-container {
  max-width: 100%;
  min-width: 0;
  min-height: 0;
  background: rgba(0, 0, 0, 0.8);
  color: rgba(255, 89, 103, 1);
  margin: 0.75rem;
  padding: 0.5rem 1rem;

  simple-snack-bar.mat-mdc-simple-snack-bar {
    font-size: 1.1rem;
    line-height: 1.9;
    text-align: center;
  }

  @media (max-width: 40rem) {
    margin: 0.5rem;
  }
}
