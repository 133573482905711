$basic-color: rgba(0, 0, 0, 0.6);

body {
  --font-family: inherit;
}

.py-03 {
  padding-top: calc(0.3 * var(--sd-base-vertical-padding)) !important;
  padding-bottom: calc(0.3 * var(--sd-base-vertical-padding)) !important;
}

.px-1 {
  padding-left: var(--sd-base-padding) !important;
  padding-right: var(--sd-base-padding) !important;
}

.basic-color {
  color: $basic-color !important;
}

.d-none {
  display: none;
}

.sd-body.sd-body--static {
  max-width: 100% !important;
  margin-left: unset;
  margin-right: unset;
}

.sd-container-modern__title.sd-title {
  margin: 0px !important;
  border-bottom: 0.5px solid $basic-color !important;
  box-shadow: none;
}

.sd-header__text {
  text-align: center !important;
  .sd-title {
    @extend .basic-color;
    font-weight: 600 !important;
    font-size: 1.5rem !important;
  }
  .sd-description {
    @extend .basic-color;
  }
}

.sd-page__title {
  @extend .py-03;
  @extend .basic-color;
  font-weight: 600 !important;
  font-size: 1.3rem !important;
  width: 100%;
  border-bottom: 0.5px solid $basic-color !important;
  box-shadow: none;
}
.sd-panel__header::after {
  bottom: calc(-0.3 * var(--sd-base-vertical-padding)) !important;
  left: 0 !important;
  width: 100% !important;
}

.sd-panel__title {
  .sv-string-viewer {
    @extend .basic-color;
    font-weight: 600 !important;
    font-size: 1.2rem !important;
  }
}

.sd-question__title {
  .sv-string-viewer {
    color: rgba(0, 0, 0, 0.87) !important;
    font-weight: 400 !important;
    font-size: 1rem !important;
  }
}

.sd-element--with-frame,
.sd-row--multiple {
  @extend .py-03;
  box-shadow: none !important;
}

.sd-row--multiple {
  /* Adjacent input fields remain at the same level */
  > div {
    .sd-element.sd-question.sd-row__question.sd-row__question--small {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      height: 100%;
    }
  }
}

.sd-html {
  @extend .py-03;
  @extend .px-1;
}

.sv-tagbox__item {
  height: calc(5 * var(--base-unit, 8px));
}

.sd-question__erbox {
  & > * {
    display: none;
  }
  & > *:first-child {
    display: block;
  }
}

.sd-list__item--selected.sv-list__item--selected {
  .sd-list__item-body.sv-list__item-body {
    background-color: var(--sjs-primary-backcolor) !important;
    color: white !important;
  }
}

.sd-dropdown__filter-string-input.sd-tagbox__filter-string-input {
  position: unset !important;
}

.sv-action__content {
  box-sizing: border-box;
}

.sd-question__erbox--above-question {
  margin-top: unset !important;
  width: unset !important;
  margin-left: unset !important;
  margin-right: unset !important;
}

.sd-element__title.sd-element__title--disabled {
  opacity: 1;
}

.sd-input--disabled {
  opacity: 1 !important;
}

.sd-action-title-bar {
  justify-content: unset !important;
}

.sd-completedpage h3 {
  @extend .basic-color;
  font-weight: 600 !important;
  font-size: 1.3rem;
}

.sv-popup__button--danger {
  background-color: var(--sjs-primary-backcolor) !important;
}

/* Media queries */
@media (max-width: 576px) {
  .sv-tagbox__item {
    height: unset;
    width: calc(19 * var(--base-unit, 8px));
  }
  .sv-tagbox__item-text {
    white-space: break-spaces;
    word-break: break-word;
    text-align: left;
  }
}
@media (max-width: 960px) {
  .sd-file__wrapper {
    width: unset !important;
  }
  .sd-action-bar.sd-file__actions-container {
    display: unset !important;
  }
}
