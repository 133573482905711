$v-form-spacing: 0.1rem;
$v-form-spacing--large: 0.3rem;
$h-form-spacing: 0.75rem;

:root {
  --v-form-spacing: #{$v-form-spacing};
}

.c-form {
  display: flex;
  flex-direction: column;
  // max-width: 100%;
  // width: 100%;
  max-width: none;
  width: auto;

  margin: -#{$v-form-spacing} -#{$h-form-spacing};
  margin-bottom: 0.5rem;
  margin: 1rem -0.5rem 0;

  > *:not(.c-form__row__item--stacked):not(.c-form__row):not(.c-form__info-banner) {
    margin: $v-form-spacing $h-form-spacing;
    margin-top: var(--v-form-spacing);
    margin-bottom: var(--v-form-spacing);
  }

  // Conditional Form-Spacing
  &--largerSpacing {
    --v-form-spacing: #{$v-form-spacing--large};
  }

  // Remove Bottom-Margin
  &--noBottomMargin {
    margin-bottom: 0;
  }

  &--noTopMargin {
    margin-top: 0;
  }

  // Smaller-Width Form
  &--smallerWidth {
    margin: 0 auto;
    max-width: 45rem;
    width: 100%;
  }

  &--scrollMargin {
    .mat-form-field-flex {
      scroll-margin-top: 3rem;
    }
  }
}

.c-form__row {
  display: flex;
  flex-wrap: wrap;

  > * {
    flex-grow: 1;
    min-width: 16rem;
    flex-basis: 45%;
  }

  > *:not(.c-form__row__item--stacked):not(.c-form__row) {
    margin: $v-form-spacing $h-form-spacing;
    margin-top: var(--v-form-spacing);
    margin-bottom: var(--v-form-spacing);
  }

  &--alignedBaseline {
    align-items: baseline;
  }

  &__item--text {
    max-width: 55ch;
    font-size: 0.9rem;
  }

  &__item--icon {
    margin: 0;
    display: flex;
    justify-content: center;
    min-width: 1rem;
    max-width: 2rem;
    width: 2rem;
    flex-grow: 0.3;
  }

  &__item--subheading {
    display: block;
    margin-top: 0.5rem !important;
    margin-bottom: 0.75rem !important;
    //margin-left: 0 !important;
    font-size: 1rem;
    font-weight: 700;
  }

  &__item--subheading-alt {
    display: block;
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
    font-weight: 600 !important;
    font-size: 0.8rem !important;
    line-height: 1.6 !important;
  }

  &__item--subheading-alt-alt {
    display: block;
    margin-top: 1rem !important;
    margin-bottom: 0.75rem !important;
    font-weight: 600 !important;
    font-size: 0.8rem !important;
    line-height: 1.6 !important;
    color: rgba(black, 0.666);
    text-transform: uppercase;
    letter-spacing: 0.5px;

    > p {
      text-transform: none;
      margin-top: 0;
      margin-bottom: 0.15rem;
      font-size: 0.9em;
      letter-spacing: 0;
    }
  }

  &__item--metaText {
    width: 20rem;
    font-size: 0.85rem;
    line-height: 1.5;
    align-self: flex-start;

    p {
      margin: 0.25rem 0;
    }
  }

  /* Sizing-Modificators */
  &__item--small {
    min-width: 10rem;
    flex-basis: 10rem;
  }

  &__item--verySmall {
    min-width: 6rem;
    flex-basis: 6rem;
  }

  &__item--xtraSmall {
    min-width: 3rem;
    flex-basis: 3rem;
  }

  .c-form__row__item--stacked .c-form__row__item--small,
  .c-form__row__item--stacked .c-form__row__item--verySmall {
    flex-basis: auto;
  }
}

.c-form .mat-form-field-subscript-wrapper {
  overflow: visible;
}

/* Fix clipping of text in mat-elements */

.c-form label.mat-form-field-label,
.c-form input.mat-input-element {
  height: 1.4em;
}

.c-form .mat-select-value {
  height: 1.4em;
  height: calc(1.4em - 1px);
}

.c-form textarea.mat-input-element {
  box-sizing: content-box;
}

/* Required-Asterix */

.mat-form-field:not(.mat-form-field-should-float)
  .mat-form-field-label
  .mat-placeholder-required.mat-form-field-required-marker {
  color: var(--errorColor);
}

// c-form__row__item--small mat-form-field ng-tns-c13-10 mat-primary mat-form-field-type-mat-input mat-form-field-appearance-legacy mat-form-field-can-float mat-form-field-should-float mat-form-field-has-label mat-form-field-hide-placeholder ng-untouched ng-pristine ng-valid

/* Mat-Hints */

// Fix Multiline Mat-Hints (https://github.com/angular/components/issues/5227#issuecomment-422181186)
.mat-form-field:not(.mat-form-field-appearance-outline) {
  .mat-form-field-wrapper {
    padding-bottom: 0;

    .mat-form-field-underline {
      position: initial !important;
      display: block;
      margin-top: -1px;
    }

    .mat-form-field-subscript-wrapper,
    .mat-form-field-ripple {
      position: initial !important;
      display: table;
    }

    .mat-form-field-subscript-wrapper {
      min-height: calc(1em + 1px);
    }
  }
}

.c-form .mat-form-field .mat-hint {
  line-height: 1.4;

  a {
    text-decoration: underline;
    cursor: pointer;
  }
}

/* Full-Height Form with Buttons fixed at the bottom */

.c-form--fullHeight {
  flex-grow: 1;

  .mat-mdc-card-actions {
    flex-grow: 1;
    align-items: flex-end;
  }
}

/* Stacked-Rows */

.c-form__row__item--stacked {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  > *:not(.c-form__row__item--stacked):not(.c-form__row) {
    margin: $v-form-spacing $h-form-spacing;
    margin-top: var(--v-form-spacing);
    margin-bottom: var(--v-form-spacing);
  }
}

/* Checkboxes */

.c-form__row--checkboxes {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  margin-bottom: 1rem;
}

/* Images */

.c-form__row__item--image {
  background: var(--secondaryBackColor);
  border-radius: 4px;
  border: 1px solid var(--secondaryLineColor);
  overflow: hidden;
  user-select: none;

  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  fa-icon {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgba(0, 0, 0, 0.15);
  }
}

/* Row-Items with Buttons */

.c-form__row__item-with-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  mat-form-field {
    flex-grow: 1;
  }

  button {
    min-width: 0;
    padding: 0 0.5rem;
    margin-left: 0.5rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 0;
  }

  button ::ng-deep fa-icon {
    opacity: 0.75;
  }
}

/* Disabled but readable form */

.c-form--readonly {
  $color: rgba(black, 0.9);
  $placeholder-color: rgba(black, 0.4);

  .mat-select-value-text,
  input.mat-input-element,
  label.mat-form-field-empty,
  textarea.mat-input-element {
    color: $color;
    -webkit-text-fill-color: $color;
    opacity: 1;
    /* required on iOS */
    font-size: 0.9rem;
    line-height: 1.25;
  }

  label.mat-form-field-empty {
    color: $placeholder-color;
  }

  mat-chip.mat-chip.mat-chip-disabled {
    opacity: 1;
    color: $color;
  }
}

/* Info-Banner */
.c-form__info-banner.c-info-banner {
  margin: 0 0 1rem 0;
  padding: 0.75rem 0;
  // padding-top: .75rem;

  .mat-dialog-content & {
    margin-left: $h-form-spacing;
    margin-right: $h-form-spacing;
  }
}

/* Mat-Slider Adjustment */
.c-form__row,
.c-form__row__item--stacked,
.c-form {
  & > .mat-slider-horizontal {
    margin: $v-form-spacing calc(#{$h-form-spacing} - 8px) !important;
  }
}

/* pulldown for betriebsstaetten select */
.c-betriebsstaetten-select__panel {
  max-height: 65vh !important;
}

/* matSuffix Icons */
.mat-mdc-form-field-icon-suffix {
  margin-right: 0.5rem;
  .mat-mdc-icon-button {
    fa-icon,
    mat-icon {
      display: flex;
    }
  }
}

/* Color Outline Style*/
.mdc-text-field--focused:not(.mdc-text-field--invalid) .mdc-notched-outline > * {
  border-color: var(--primaryColor) !important;
}
.mdc-text-field--focused:not(.mdc-text-field--invalid) .mdc-floating-label,
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused:not(.mdc-text-field--invalid) .mdc-floating-label,
.mat-mdc-form-field.mat-focused.mat-primary .mat-mdc-select-arrow {
  color: var(--primaryColor);
}
.mdc-text-field--disabled:not(.mdc-text-field--invalid) .mdc-notched-outline > * {
  border-color: rgba(0, 0, 0, 0.24) !important;
}
.mdc-text-field--disabled:not(.mdc-text-field--invalid) .mdc-notched-outline__notch {
  border-left: none !important;
}
.mdc-text-field--disabled:not(.mdc-text-field--invalid) .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.55) !important;
}

/* Prevent floating labels (appearance='outline') from being cut off*/
.mat-mdc-form-field {
  --mat-mdc-form-field-floating-label-scale: 0.8 !important;
}
.mat-mdc-form-field .mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  font-size: 15px;
}

/* Form field with chip-list */
.c-form__chip {
  .mat-mdc-form-field-infix {
    width: auto;
    .mat-mdc-chip-grid .mat-mdc-chip-row {
      min-width: fit-content;
    }
  }
}

.mat-form-field-appearance-outline {
  .mat-mdc-form-field-infix {
    .mat-mdc-chip-grid .mat-mdc-chip-row {
      background-color: var(--secondaryBackColor);
    }
  }
}

/* Form field with long hint */

.form-field__long-hint {
  .mat-mdc-form-field-subscript-wrapper {
    display: flex;

    .mat-mdc-form-field-hint-wrapper {
      position: relative;

      .mat-mdc-form-field-hint {
        display: flex;
      }
    }
  }
}
