/* dsgvo.component, displayed as a bottom sheet */

.c-bottom-sheet.mat-bottom-sheet-container {
  padding: 1.25rem 1.75rem;
  min-width: 0;
  max-width: 100vw;
  width: 45rem;
  border-top-right-radius: 16px;
  border-top-left-radius: 16px;
}

.c-bottom-sheet-backdrop {
  background: rgba(black, 0.5);
  pointer-events: none;
}
