@use "theme-definitions";
@use '@angular/material' as mat;
/* Custom Typography */

body,
body app-root *,
body arzt-direkt-booking *,
body landing-page-root * {
  font-family: "Open Sans", serif;
}

$custom-typography: mat.m2-define-typography-config(
  $font-family: "Open Sans, serif",
);

/* Easy Coloring Helper-Classes */

.isAccent {
  color: var(--secondaryColor);
}
.isPrimary {
  color: var(--primaryColor);
}
.isError {
  color: var(--errorColor) !important;
}
.isWarning {
  color: var(--warningColor);
}
.isSuccess {
  color: var(--successColor);
}
.isInfo {
  color: var(--infoColor);
}

/* Angular Theme Initialization */
