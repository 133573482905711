mat-checkbox.mat-mdc-checkbox {
  .mat-checkbox-layout {
    align-items: flex-start;
    white-space: normal;
  }
  .mat-checkbox-inner-container {
    margin-top: 0.25rem;
  }
  label {
    font-size: 0.9rem;
    line-height: 1.7;

    a {
        // font-weight: 600;
        text-decoration: underline;
        color: var(--primaryColor);
      }
  }
}

.mat-mdc-checkbox.mat-mdc-checkbox-checked.mat-primary .mdc-checkbox__background,
.mdc-checkbox__ripple {
  background-color: var(--primaryColor) !important;
  border-color: var(--primaryColor) !important;
}
