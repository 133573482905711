@use "mixins" as mixins;
$item-border-radius: 4px;
$item-icon-size: 1.25rem;

.c-primary-list {
  border-radius: $item-border-radius;
  //background-color: rgba(black, 0.02);
  background-color: var(--primaryBackColor);
  overflow: hidden;

  transition: all 0.2s ease-in-out;
  transition-property: box-shadow, background-color;
  will-change: box-shadow, background-color;

  &--isDisabled {
    pointer-events: none !important;
    opacity: 0.4 !important;
    user-select: none !important;
  }
}

.c-primary-list__item {
  padding: 1.25rem #{2rem + $item-icon-size} 1.25rem 1rem;
  display: flex;
  position: relative;
  justify-content: space-between;
  align-items: center;
  border: 1px var(--secondaryLineColor) solid;
  word-break: break-all;
  background-color: transparent;

  // Border & Border-Radius
  &:not(:last-child) {
    border-bottom: 0;
  }
  &:first-child {
    border-top-left-radius: $item-border-radius;
    border-top-right-radius: $item-border-radius;
  }
  &:last-child {
    border-bottom-left-radius: $item-border-radius;
    border-bottom-right-radius: $item-border-radius;
  }

  // Title & Action-Text
  &__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-right: 2rem;
    overflow: hidden;
  }
  &__text h3 {
    display: inline;
    font-size: inherit;
    font-weight: 600;
    margin: 0;
    @include mixins.truncate();
  }
  &__text p {
    font-size: 0.8rem;
    text-decoration: none;
    color: inherit;
    // font-weight: 600;
    margin: 0.2rem 0;
    word-break: break-word;
  }
  &__text p fa-icon {
    margin-left: 0.2rem;
    color: var(--primaryColor);
  }
  &__text--isCentered {
    padding-right: 0;
    margin: 0 auto;
  }

  // Button
  &__aside {
    display: flex;
    margin-left: auto;
    font-size: 0.85rem;
  }
  &__aside p {
    font-weight: 600;
    white-space: nowrap;
    margin: 0.15rem 0;
  }
  &__aside button {
    padding: 0.4rem 0.8rem 0.45rem;
    margin: 0.15rem 0;
    line-height: inherit;
    min-width: auto;

    &.mat-button {
      font-weight: 600;
    }
    // &.mat-stroked-button {
    //   font-weight: 600;
    //   background: white;
    // }
  }

  // Icons
  > fa-icon {
    // display: none;
    position: absolute;
    right: 0.9rem; // to align pp with online-doctors
    top: 50%;
    transform: translateY(-50%);
    font-size: 1.25rem;
    width: $item-icon-size;
    text-align: center;
  }

  // Clickable Items
  .c-primary-list:not(.c-primary-list--isDisabled) &--isClickable {
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: rgba(black, 0.05);
    }
  }

  // Narrow Items
  &--isNarrow {
    padding: 0.8rem 1rem;

    .c-primary-list__item__text p {
      margin: 0;
      color: rgba(0, 0, 0, 0.6);
      font-size: 0.8rem;
    }
  }

  // Head Items
  &--isHead {
    background-color: rgba(white, 0.25);
  }

  // Item-States
  &--isSuccess {
    background-color: rgba(0, 200, 83, 0.1);
  }
  &--isWarning {
    background-color: var(--warningColor);
  }
}

/* Lowered / Elevated Transparent List-Styles */

.c-primary-list--isLowered,
.c-primary-list--isElevated {
  .c-primary-list__item {
    background-color: transparent;
    border: 1px var(--secondaryLineColor) solid;

    &--isClickable:hover,
    &--isClickable:focus {
      //background-color: rgba(black, 0.05);
      background-color: var(--secondaryBackColor) !important;
    }
    &--isHead {
      //background-color: rgba(white, 0.25);
      background-color: var(--primaryBackColor);
    }
  }
}
.c-primary-list--isLowered {
  background-color: rgba(white, 0.6);
  box-shadow: none;
}
.c-primary-list--isElevated {
  background-color: white;
  //box-shadow: 0 3px 12px hsla(var(--mainValue), 90%, 30%, 0.16);

  .c-primary-list__item--isHead {
    background-color: var(--primaryBackColor);
  }
}

/* Responsiveness */

@media (max-width: 30rem) {
  .c-primary-list__item:not(.c-primary-list__item--isNarrow) {
    flex-wrap: wrap;

    .c-primary-list__item__aside {
      align-items: flex-start;
      margin-left: 0;
      margin-top: 0.5rem !important;
    }

    .c-primary-list__item__text {
      padding-right: 0;
      width: 100%;
    }
  }
}
