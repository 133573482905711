@use '../helpers/grow-vertically' as growVertically;
$border-color: rgba(0, 0, 0, 0.12);

.c-tab-group {
  font-size: 0.9rem;
  user-select: none;

  &.c-tab-group-no-header {
    .mat-mdc-tab-header {
      display: none;
    }
    .descendent-with-header {
      .mat-mdc-tab-header {
        display: flex;
      }
    }
  }
}

.c-tab-group .mat-mdc-tab-header {
  border-bottom: 1px solid $border-color;
}

.c-tab-group .mdc-tab__text-label {
  font-weight: 600;
  height: 3.5rem;
  color: rgba(0, 0, 0, 0.65);
  opacity: 1;

  &.mdc-tab--active {
    color: rgba(0, 0, 0, 0.9);
  }
  &.mat-mdc-tab-disabled {
    color: rgba(0, 0, 0, 0.35);
    cursor: not-allowed;
  }
  &:not(.mat-mdc-tab-disabled):hover,
  &:not(.mat-mdc-tab-disabled):focus {
    color: rgba(0, 0, 0, 0.9);
  }

  fa-icon:first-child {
    margin-right: 0.4rem;
  }
  fa-icon:last-child {
    margin-left: 0.4rem;
  }
}

.c-tab-group .mat-mdc-tab-body-content {
  // Prevent e.g. .c-form from producing a scrollbar because of their negative margin
  overflow: hidden;
}

/* Full-Height Tab-Content */

.c-tab-group--fullHeight {
  &,
  .mat-mdc-tab-body,
  .mat-mdc-tab-body-wrapper,
  .mat-mdc-tab-body-content {
    @include growVertically.grow-vertically();
  }
}

/* Reponsive Pagination */

.c-tab-group .mat-mdc-tab-header .mat-mdc-tab-header-pagination {
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;

  &-disabled .mat-mdc-tab-header-pagination-chevron {
    border-color: rgba(0, 0, 0, 0.15);
  }
  &:not(.mat-mdc-tab-header-pagination-disabled) {
    box-shadow: 0 0 3px 0px rgba(0, 0, 0, 0.15);

    .mat-mdc-tab-header-pagination-chevron {
      border-color: rgba(0, 0, 0, 0.87);
    }
  }
}

/* Tab-Header in dockedItem */
.c-tab-group--docked{
  height: auto;
  min-height: 2.1rem;
  .mat-mdc-tab-header {
    width: fit-content;
    border-bottom: none;
    .mdc-tab{
      font-size: small;
      padding: 0 14px;
      height: 2rem;
      text-transform: uppercase;
      min-width: auto;
      &:active .mdc-tab-indicator {
        background-color: var(--secondaryCardColor);
      }
      &:focus .mdc-tab-indicator {
        background-color: var(--primaryCardColor);
      }
      &:not(.mat-mdc-tab-disabled):hover .mdc-tab-indicator {
        background-color: var(--secondaryCardColor);
      }
      &.mat-mdc-tab-disabled:hover .mdc-tab-indicator {
        background-color: var(--primaryCardColor);
      }
      &.mdc-tab--active {
        .mdc-tab__text-label{
          color: rgba(0, 0, 0, 0.9);
        }
        .mdc-tab-indicator__content--underline{
          border-color: rgba(0, 0, 0, 0.9);
          margin: 0 10px;
        }
      }
    }
  }
  fa-icon {
    position: unset !important;
  }
}
