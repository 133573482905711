/* Style-Font */

@mixin style-font() {
  font-family: "Freestyle Script";
  letter-spacing: 0.005em;
  font-weight: normal;
  font-style: normal;
}

/* Clearfix */

@mixin clearfix($important: false) {
  // Determine whether or not to make declarations important.
  @if ($important == false) {
    $important: null;
  } @else {
    $important: !important;
  }

  &:after {
    content: "" $important;
    display: table $important;
    clear: both $important;
  }
}

/* Visually hides an element but still accessible */

@mixin visually-hidden() {
  position: absolute !important;
  overflow: hidden !important;
  clip: rect(1px 1px 1px 1px) !important;
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px) !important;
  width: 0 !important;
  height: 1px !important;
}

/* Primary (Blue) Gradient */

// @mixin primary-gradient($top: 300, $bottom: 900) {
//   background-image: linear-gradient(-180deg, #{mat-color($primary, $top)} 0%, #{mat-color($primary, $bottom)} 100%);
// }

/* Truncate Text-Overflow */

@mixin truncate() {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/**
* Custom Multiline Underlined Links.
* NOTE: Multiline only works with `display: inline;`
*/
@mixin underlined-link-multiline($color: black, $width: 1px, $offset: 1px) {
  text-decoration: none;
  background-image: linear-gradient(to top, transparent 0%, $color 0%, $color $width, transparent $width);
  padding-bottom: $offset;
}
