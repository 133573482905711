$page-max-width: 60rem;

/* Wrapper / Container */

@mixin o-wrapper-base {
  position: relative;
  width: $page-max-width;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 2rem;
  padding-left: 2rem;
}

@media (max-width: 40rem) {
  .o-wrapper-base {
    padding-right: 1rem;
    padding-left: 1rem;
  }
}
