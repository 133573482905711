

.mat-option.mat-option--isMultiline {

  .mdc-list-item__primary-text {
    display: inline-flex;
    flex-direction: column;
    height: 100%;
    line-height: normal;
    justify-content: center;
    flex-grow: 1;
    mat-icon {
        vertical-align: middle;
      }
  }

  .mdc-list-item__primary-text > span:last-child {
    color: black;
    font-size: .8em;
    opacity: .65;
  }

  &.mat-option-disabled .mdc-list-item__primary-text > span:last-child {
    opacity: .38;
  }

  // &.mat-selected .mdc-list-item__primary-text > span:last-child {
  //  opacity: 1;
  // }

}

.mat-mdc-option .mdc-list-item__primary-text {
  display: inline-flex;
    flex-direction: row;
    // height: 100%;
    line-height: normal;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
    padding: 0.25em 0;
    mat-icon {
        vertical-align: middle;
      }
  span:last-child:not(:first-child) {
    color: black;
    font-size: .8em;
    opacity: .65;
    flex-basis: 100%;
    line-height: 1.3em;
  }
}


.mat-option.mat-option--isMulticolumn {

  .mdc-list-item__primary-text {
    display: inline-flex;
    flex-direction: row;
    // height: 100%;
    line-height: normal;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-grow: 1;
    mat-icon {
      vertical-align: middle;
    }
  }

}


.mat-primary .mat-mdc-option .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full{
  background: var(--primaryColor);
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text{
  color: var(--primaryColor);
}

.mat-primary .mat-mdc-option .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after{
  color: var(--primaryColor);
}

.mat-primary .mat-mdc-option .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: var(--primaryColor);
}

.mat-primary .mat-mdc-option .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal {
  display: none;
}

.mat-primary.mat-mdc-list-option .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-primary.mat-mdc-list-option .mdc-checkbox:not(:disabled):active .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-primary.mat-mdc-list-option .mdc-checkbox:not(.mdc-ripple-upgraded):focus .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-primary.mat-mdc-list-option .mdc-checkbox:hover .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background{
  background-color: var(--primaryColor);
  border-color: var(--primaryColor);
}