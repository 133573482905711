$image-padding: 0.75rem;

.c-arzt-photo {
  position: relative;
  min-width: 12rem;
  max-width: 15rem;
  margin-top: 1.5rem !important;
  margin-bottom: 1rem !important;
  padding: $image-padding;

  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc(1 / 1) * 100%;
  }

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: $image-padding;
    left: $image-padding;
    right: $image-padding;
    bottom: $image-padding;
    border-radius: 50%;
    background: white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  }

  > fa-icon {
    z-index: 10;
  }

  > img {
    position: absolute;
    top: $image-padding;
    left: $image-padding;
    width: calc(100% - #{2 * $image-padding});
    height: calc(100% - #{2 * $image-padding});
    overflow: hidden;
    object-fit: cover;
    z-index: 1;
    border-radius: 50%;
  }
}
