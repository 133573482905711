
hr {
  margin: 1rem auto 1.25rem !important;
  height: 1px;
  background: rgba(0, 0, 0, 0.05);
  width: calc(100% - 1rem);
  border: none;
}

// full width in c-card with padding 1.5rem left + right
.hr--fullWidth {
  margin: 0.5rem -1.5rem !important;
  width: auto;
}