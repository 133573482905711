/* General Refinements */

.c-stepper {
  background: transparent !important; // For Overflow Scroll-Indicator

  &.no-stepper-header {
    .mat-horizontal-stepper-header-container {
      display: none;
    }
  }
}

.c-stepper .mat-step-header {
  transform: translate3d(0, 0, 0);
  border-radius: 4px;

  &.mat-vertical-stepper-header {
    height: 1rem;
  }
  &.mat-horizontal-stepper-header {
    height: 4rem;
  }

  &[ng-reflect-selected="true"] {
    background: rgba(0, 0, 0, 0.04);
  }
  &[ng-reflect-state="done"]:hover {
    background: inherit;
    cursor: default;
  }
}

.c-stepper .mat-step-icon-state-error,
.c-stepper .mat-step-icon-state-done,
.c-stepper .mat-step-icon-state-edit,
.c-stepper .mat-step-icon-state-number {
  font-weight: 600;
  font-size: 0.9rem;
  line-height: 100%;
  user-select: none;

  &.mat-step-icon-selected {
    font-weight: bold;
  }
  &.mat-step-icon-state-error {
    font-size: 1.25rem;
  }
  &.mat-step-icon-state-done {
    background-color: var(--successColor);
  }
}

.c-stepper .mat-step-label {
  line-height: 1.5;
  font-weight: 600;
  user-select: none;
  min-width: auto;

  &:not(.mat-step-label-selected) {
    color: rgb(90, 90, 90) !important;
  }
  &.mat-step-label-selected {
    color: black !important;
  }
}
.c-stepper.c-stepper--headersDisabled .mat-step-header {
  pointer-events: none !important;
}

.c-stepper .mat-stepper-vertical-line::before {
  top: -0.75rem;
  bottom: -0.75rem;
}

.c-stepper .mat-vertical-content {
  padding: 0.5rem 1.5rem;
}

.c-stepper .mat-horizontal-stepper-content[aria-expanded="false"] {
  display: none;
}

/* Hide Stepper-Headers */

.c-stepper.c-stepper--hideHeaders .mat-horizontal-stepper-header-container {
  display: none !important;
}

/* Full-Height Stepper */

.c-stepper.c-stepper--isFullHeight {
  width: 100%;

  .mat-horizontal-content-container {
    padding: 2rem 1.5rem 0;
    padding: calc(0.5rem + 2vh) calc(0.5rem + 2vw) 0;
  }

  &,
  .mat-horizontal-content-container,
  .mat-horizontal-stepper-content[aria-expanded="true"],
  .mat-horizontal-stepper-content[aria-expanded="true"] > * {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

/* Responsiveness */

@media (max-width: 45rem) {
  .c-stepper .mat-horizontal-stepper-header-container {
    justify-content: space-around;
    flex-wrap: wrap;
  }
  .c-stepper .mat-vertical-content-container {
    margin-left: 1.5rem;
  }
  .c-stepper .mat-stepper-vertical-line::before {
    top: -0.4rem;
    bottom: -0.4rem;
  }
  .c-stepper .mat-step-header.mat-vertical-stepper-header {
    padding: 1rem 0.75rem;
  }
  .c-stepper .mat-vertical-content {
    padding-right: 0.75rem;
  }
  .c-stepper .mat-step-header.mat-horizontal-stepper-header {
    padding: 0 0.75rem;
    height: 3rem;
  }
  .c-stepper .mat-step-label {
    font-size: 0.8rem;
  }
  .c-stepper .mat-stepper-horizontal-line {
    margin: 0 -0.25rem;
    flex: 1 1 0;
    min-width: auto;
  }
}

@media (max-width: 45rem) {
  .c-stepper .mat-horizontal-stepper-header-container {
    flex-wrap: nowrap;
  }
  .c-stepper .mat-step-header.mat-horizontal-stepper-header {
    padding: 0 1rem;
  }
  .c-stepper .mat-stepper-horizontal-line {
    margin: 0 -0.25rem;
    flex: 1 1 0;
    min-width: auto;
  }
  .c-stepper .mat-horizontal-stepper-header .mat-step-label:not(.mat-step-label-selected) {
    display: none;
  }
  .c-stepper .mat-horizontal-stepper-header .mat-step-icon:not(.mat-step-icon-selected) {
    margin-right: 0;
  }
}


/* Stepper in dockedItem */
.c-stepper--docked {
  height: 2.5rem;

  .mat-horizontal-stepper-wrapper {
    //width: fit-content;

    .mat-horizontal-stepper-header-container .mat-step-header {
      background: transparent;
      height: 2.5rem;

      &[ng-reflect-selected="true"]{
        background: transparent;
      }

      .mat-step-label{
        color: var(--primaryColor) !important;
        &:not(.mat-step-label-selected) {
          opacity: 0.6;
        }
        &.mat-step-label-selected {
          opacity: 1;
        }
      }

      .mat-step-icon {
        background: var(--primaryColor) !important;
        &:not(.mat-step-icon-selected) {
          opacity: 0.6;
        }
        &.mat-step-icon-selected {
          opacity: 1;
        }
      }
    }
    .mat-horizontal-content-container{
      display: none;
    }
  }
}

/* Stepper Content without padding */

.c-stepper.c-stepper--no-padding{
  .mat-horizontal-stepper-wrapper {
    height: 100%;
  
    .mat-horizontal-content-container {
      padding: 0;
      display: contents;
  
      .mat-horizontal-stepper-content:not(.mat-horizontal-stepper-content-inactive) {
        height: 100%
      }
    }
  }
}

/* Stepper Header without inactive Labels */

.c-stepper.c-stepper--noInactiveLabels .mat-horizontal-stepper-header .mat-step-label:not(.mat-step-label-selected) {
  display: none;
}