@use "theming";
@use "theme";
@use "mixins";
@use "global";
@use "material-icons/iconfont/material-icons.css";

@use "../../../node_modules/leaflet/dist/leaflet.css";

/**
* Components (from `/shared-scss`)
*/

@use "components/bottom-sheet";
@use "components/form";
@use "components/hr";
@use "components/abbr";
@use "components/icons";
@use "components/menu";
@use "components/button-group";
@use "components/primary-list";
@use "components/accordion";
@use "components/dialog";
@use "components/checkbox";
@use "components/counter";
@use "components/tab-group";
@use "components/instructions";
@use "components/table";
@use "components/arzt-photo";
@use "components/card";
@use "components/option";
@use "components/slide-toggle";
@use "components/snackbar";
@use "components/stepper";
@use "components/radio-group";
@use "components/person";
@use "components/info-banner";
@use "components/info-section";
@use "components/flickity";
@use "components/typography";
// the quill styles are conflicting since changing @import to @use so they were aliased. This could cause them to not be identified throughout the website.
@use "quill/dist/quill.core.css" as quillCore;
@use "quill/dist/quill.bubble.css" as quillBubble;
@use "quill/dist/quill.snow.css" as quillSnow;
@use "highlight.js/styles/github.css";

.ql-picker-options {
  z-index: 10 !important;
}

.ql-toolbar.ql-snow + .ql-container.ql-snow {
  border: 1px solid #ccc !important;
}

.ql-tooltip {
  z-index: 10 !important;
}

.ql-tooltip[data-mode="image"]::before {
  content: "Enter image URL:";
}

.streetmap-overlay {
  /*TODO(mdc-migration): The following rule targets internal classes of dialog that may no longer apply for the MDC version.
*/
  .mat-mdc-dialog-surface.mdc-dialog__surface {
    padding: 0.5rem 0.5rem 0.5rem 0.5rem !important;
    box-sizing: border-box;
    overflow: hidden;
  }
}

.portal-search-tooltip {
  max-width: unset !important;
}

.council-dialog-backdrop {
  background-color: rgba(0, 0, 0, 0.7);
}


/* 
  Many floating windows / pop-ups from angular material are overlays (see: https: //material.angular.io/cdk/overlay/api).
  When those pop-ups become to large the content should be scrollable. However, to achive this, the 'cdk-overlay-pane'
  has to have height specified. As we don't want to set styles for the angular material class (would need ng::deep)
  we set a custom class for the overlay pane with: cdkConnectedOverlayPanelClass="height-aware-overlay"
  (For some reason the style for this custom class needs to be global, hence here - also see: https: //stackoverflow.com/questions/45610137 )
*/
.height-aware-overlay {
  height: 100%;
}