@use "mixins" as mixins;
/* Visually Hidden Helper */

.o-vh {
  @include mixins.visually-hidden();
}

@media (max-width: 49.9375rem) {
  .hide--onSmall {
    @include mixins.visually-hidden();
  }
  .remove--onSmall {
    display: none !important;
  }
}
@media (min-width: 50rem) {
  .show--onSmall {
    @include mixins.visually-hidden();
  }
  .remove--onLarge {
    display: none !important;
  }
}
@media (max-width: 34.9375rem) {
  .hide--onVerySmall {
    @include mixins.visually-hidden();
  }
  .remove--onVerySmall {
    display: none !important;
  }
}
@media (min-width: 35rem) {
  .show--onVerySmall {
    @include mixins.visually-hidden();
  }
  .remove--onSmall-onLarge {
    display: none !important;
  }
}
