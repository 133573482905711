
/* Draggable Cursor */

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: grab;
}


/* Flickity Pagination-Dots */

.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
  user-select: none;

  .dot {
    display: inline-block;
    width: .5rem;
    height: .5rem;
    margin: 0 .5rem;
    background: black;
    border-radius: 50%;
    opacity: 0.125;
    cursor: pointer;
  }
  .dot.is-selected {
    opacity: 0.6;
  }
}


/* Flickity Prev/Next-Buttons */

.flickity-button {
  color: rgba(0,0,0,.8);
  border: 2px rgb(235,235,235) solid;
  border-radius: 50%;
  background: white;
  line-height: 100%;
  cursor: pointer;
  padding: .45rem;
  width: 2rem;
  height: 2rem;

  .flickity-button-icon {
    fill: currentColor;
  }

  &:hover, &:focus {
    color: black;
    border-color: rgb(215,215,215)
  }
}

.flickity-prev-next-button {
  position: absolute;
  z-index: 100;
  right: 0;
  top: 50%;
  transform: translateY(-50%);

  &.previous {
    left: 2rem;
  }
  &.next {
    right: 2rem;
  }
}
