
/* Grow Vertically Flexbox-Helper */

@mixin grow-vertically() {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.o-gv {
  @include grow-vertically();
}

