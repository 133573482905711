$banner-v-padding: 0.4rem;
$banner-h-padding: 0.75rem;

$normal-bg: rgb(253, 253, 253);
$normal-border: rgb(241, 241, 241);

$warning-bg: rgb(254, 255, 187);
$warning-border: rgb(241, 242, 172);

$error-bg: rgb(255, 217, 217);
$error-border: rgb(247, 197, 197);

$success-bg: rgb(221, 254, 207);
$success-border: rgb(194, 237, 172);

$accent-bg: rgb(255, 228, 216);
$accent-border: rgb(255, 201, 185);

$primary-bg: rgb(227, 242, 253);
$primary-border: rgb(187, 222, 251);

.c-info-banner {
  display: block;
  color: inherit;
  text-decoration: none;
  position: relative;
  margin-bottom: 1rem;
  border-radius: 5px;
  padding: #{$banner-v-padding} #{$banner-h-padding} #{$banner-v-padding};
  border: 1px var(--primaryBackColor) solid;
  margin-top: 1rem;

  h4 {
    margin: 0.15rem 0 0.2rem;
    font-size: 0.8rem;
  }
  h4 > fa-icon + span {
    margin-left: 0.5rem;
  }
  p {
    font-size: 0.85rem;
    margin: 0.25rem 0;
    line-height: 1.5;

    a {
      color: inherit;
      white-space: nowrap;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  h4 > fa-icon {
    display: inline-block;
    transform: translateY(-0.03125em); // Pixel Perfectionist
  }

  /* Is Link */
  &--isClickable {
    cursor: pointer;
  }
  &--isClickable:hover,
  &--isClickable:focus {
    filter: brightness(95%);
  }

  /* Close Icon */

  &--hasClose p {
    padding-right: 1.25rem;
  }

  &__close {
    position: absolute;
    right: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0.5;
    cursor: pointer;
    color: black;

    &:hover,
    &:focus {
      opacity: 1;
    }
  }

  /* Different Styles */

  &.normal {
    background: $normal-bg;
    border-color: $normal-border;
  }
  &.accent {
    background: $accent-bg;
    border-color: $accent-border;
  }
  &.primary {
    background: $primary-bg;
    border-color: $primary-border;
  }
  &.success {
    background: $success-bg;
    border-color: $success-border;
  }
  &.warning {
    background: $warning-bg;
    border-color: $warning-border;
  }
  &.error {
    background: $error-bg;
    border-color: $error-border;
  }

  /* Centering */

  &--centeringAll {
    text-align: center;
  }
  &--centeringTitle > h4 {
    text-align: center;
  }
}

/* Under Title-Bar */

.c-card__title-stick > .c-info-banner,
.c-info-banner.c-info-banner--fullWidth {
  display: block;
  text-align: center;
  border-radius: 0;
  border-left-width: 0;
  border-right-width: 0;
  padding-left: 1.75rem;
  padding-right: 1.75rem;
  margin-bottom: 0;
  margin-top: 0;

  p {
    max-width: 75ch;
    margin-left: auto;
    margin-right: auto;
  }
}

/** Hinweisliste **/

.c-info-banner--hinweisListe {
  display: inline-block;

  summary {
    cursor: pointer;
    user-select: none;
  }
  h4 {
    display: inline;
  }
  ul {
    text-align: left;
  }
  ul > li:not(:last-child) {
    margin-bottom: 0.3rem;
  }
}

/** Small-Form **/

.c-info-banner--smallForm {
  padding: 0.85rem 0.25rem 0;

  mat-form-field {
    .mat-input-element,
    .mat-form-field-label-wrapper,
    .mat-form-field-suffix {
      font-size: 0.8rem;
    }
    .mat-input-element,
    .mat-form-field-label-wrapper {
      font-weight: 600;
    }
    .mat-form-field-subscript-wrapper {
      display: none;
    }
  }

  + mat-form-field {
    margin-top: 1.5rem !important;
  }
}
