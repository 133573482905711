mat-accordion {
  mat-expansion-panel.mat-expanded {
    background: rgb(248, 248, 248);
  }
  .mat-expansion-panel-body p {
    margin-top: 0;
    line-height: 1.6;
    font-size: 0.85rem;
  }
  mat-expansion-panel-header:hover .mat-expansion-indicator::after {
    color: var(--secondaryColor);
  }
}

.c-expansion-flat{
  
    .mat-expansion-panel {
      background: white;
      box-shadow: none;
      border-radius: 0;
    
      &+.mat-expansion-panel {
        border-top: 1px solid var(--secondaryLineColor);
      }

      .mat-expansion-panel-header{
      &:hover{
        background: var(--secondaryCardColor);
      }
    }
    }
    
    .mat-expansion-panel-header-title {
      font-weight: bold;
      opacity: 0.7;
      color: var(--secondaryTextColor);
    }

    .mat-expansion-panel-header-title .open{
      display: flex;
      position: absolute;
      right: 1rem;
      color: rgba(0, 0, 0, 0.87);
    }
    
    .mat-expanded .mat-expansion-panel-header-title .open{
      display: none;
    }

    .mat-expanded .stay-open{
      cursor: auto;
      pointer-events: none;
    }
  }