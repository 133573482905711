.info-section {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  mat-icon {
    color: var(--infoColor);
    margin-top: 0.25rem;
    margin-right: 1rem;
  }

  h2 {
    margin: 0;
    color: var(--primaryTextColor);
  }
  p {
    margin: 0.5rem 0;
    color: var(--secondaryTextColor);
  }

  .info-section-text {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}